import { useEffect } from "react";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";
import './App.css';

export default function AboutPage() {

  useEffect(() => {
    console.info('View About');
    Sentry.captureMessage(`capture About ${JSON.stringify({ user: "Guga", id: 123 })}`);
  }, []);

  return (
    <div id="about-page" className="app">
      <h1>About</h1>
      <Link to={`/`} className="back">back</Link>
    </div>
  );
}