import { useEffect } from "react";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/react";
import './App.css';

export default function ContactPage() {

  useEffect(() => {
    console.log('View Contact');
    Sentry.captureMessage("capture Contact");
  }, []);

  return (
    <div id="contact-page" className="app">
      <h1>Contact</h1>
      <Link to={`/`} className="back">back</Link>
    </div>
  );
}