import { Link } from "react-router-dom";
import "./App.css";

function App() {
  return (
    <div className="app">
      <h1>Sentry</h1>
      <Link to={`about`} className="link">About</Link>
      <Link to={`contact`} className="link">Contact</Link>
    </div>
  );
}

export default App;
